import React from "react"

import { Container } from "react-bootstrap"
import Layout from "components/layout"
import SEO from "components/seo"

const FourOhFourPage = () => {
  return (
    <div className="background-grey">
      <Layout pageInfo={{ pageName: "404" }}>
        <SEO title="Demo" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Container fluid className="px-0">
          <h1>404</h1>
        </Container>
      </Layout>
    </div>
  )
}

export default FourOhFourPage
